import { Link } from 'react-router-dom';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonNote,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonIcon,
  IonChip,
  IonThumbnail
} from '@ionic/react';

const Activity: React.FC = () => {
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonSegment value="1" onIonChange={e => console.log('Segment selected', e.detail.value)} color="primary">
            <IonSegmentButton value="1">
              <IonLabel>Đơn Mio (0)</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="2">
              <IonLabel>Đơn Sơn (0)</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="ion-padding bg-white">
          <IonSegment value="1" onIonChange={e => console.log('Segment selected', e.detail.value)} mode="ios">
            <IonSegmentButton value="1">
              <IonLabel>Chờ (0)</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="2">
              <IonLabel>Đang (0)</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="3">
              <IonLabel>Xong</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="4">
              <IonLabel>Hủy</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </div>
        <div className="order-list ion-padding">
          <div className="order-item bg-white ion-padding ion-margin-bottom rounded">
            <Link to="/order/12333">
              <div className="order-item-header d-flex ion-justify-content-between ion-align-items-center">
                <div>
                  <IonNote color="primary" className="fs-2 fw-bold">28,500đ</IonNote>
                  <div><IonLabel color="dark"><small>13:22 15/06 - LTHP01506132027004</small></IonLabel></div>
                </div>
                <IonChip color="danger" className="ion-no-margin">Chờ</IonChip>
              </div>
              <div className="d-flex ion-justify-content-between border-top ion-margin-top ion-padding-top ion-align-items-center">
                <div className="d-flex ion-align-items-center">
                  <IonThumbnail slot="start">
                    <img src="https://api.itaphoa.com/photos/fb3d3520aa8cde29f10e7cc53c902e5b.png"/>
                  </IonThumbnail>
                  <div className="ml-1">
                    <p className="ion-no-margin"><IonLabel color="dark" className="fw-bold fs-4">Dưa lưới TL3 (Trái từ 0.8 - dưới 1 kg)</IonLabel></p>
                    <IonLabel color="dark"><small>Trái = 28,500₫</small></IonLabel>
                  </div>
                </div>
                <small className="text-muted">Đã hủy</small>
              </div>
              <div className="d-flex ion-justify-content-between border-top ion-margin-top ion-padding-top ion-align-items-center">
                <div className="d-flex ion-align-items-center">
                  <IonThumbnail slot="start">
                    <img src="https://api.itaphoa.com/photos/0762d2752b82a22fe104cf68efe5dc0d.png"/>
                  </IonThumbnail>
                  <div className="ml-1">
                    <p className="ion-no-margin"><IonLabel color="dark" className="fw-bold fs-4">Sả Cây</IonLabel></p>
                    <IonLabel color="dark"><small>100 gram = 7,500₫</small></IonLabel>
                  </div>
                </div>
                <small className="text-muted">Đã hủy</small>
              </div>
            </Link>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Activity;
