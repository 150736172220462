import React from "react";

// create the context
export const UIContext = React.createContext<any>(undefined);

// create the context provider, we are using use state to ensure that
// we get reactive values from the context...

export const UIProvider: React.FC = ({ children }) => {

  // the reactive values
  const [showTabs, setShowTabs] = React.useState(true);
  const [showQuickView, setShowQuickView] = React.useState(false);


  // the store object
  let state = {
    showTabs,
    setShowTabs,
    showQuickView,
    setShowQuickView
  };

   // wrap the application in the provider with the initialized context
  return <UIContext.Provider value={state}>{children}</UIContext.Provider>;

}

export default UIContext;
