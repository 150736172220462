import React, { useEffect } from "react";
import { RouteComponentProps } from 'react-router-dom';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonAvatar,
  IonIcon,
  IonLabel,
  IonButton,
  IonImg,
  IonItem,
  useIonToast
} from '@ionic/react';
import { person, pencil } from 'ionicons/icons';

// Context
import { StoreContext } from "../../context/store";
import { UIContext } from "../../context/ui";

const Account: React.FC<RouteComponentProps> = ({history}) => {
  const { setShowTabs } = React.useContext(UIContext);
  const { isLogin, setIsLogin } = React.useContext(StoreContext);
  const userData = JSON.parse(localStorage.getItem('userData')!);
  const [presentToast, dismissToast] = useIonToast();
  useEffect(() => {
    return () => {
      setShowTabs(isLogin);
    };
  });

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonTitle>Tài khoản</IonTitle>
        </IonToolbar>
      </IonHeader>
      {isLogin && userData.id ? (
      <IonContent fullscreen>
        <div className="text-center ion-padding">
          <div className="ion-margin-bottom">
            <div className="d-inline-block position-relative">
              <IonAvatar className="avatar m-auto">
                <IonIcon icon={userData.avatar && userData.avatar.id ? userData.avatar.url : person} size="large" color="primary"/>
              </IonAvatar>
              <IonButton className="btn-avatar-edit" fill="outline" mode="ios" shape="round" size="small" style={{ '--padding-start': 0, '--padding-end': 0, '--background': '#fff' }}>
                <IonIcon icon={pencil} />
              </IonButton>
            </div>
          </div>
          <IonLabel>{userData.name}</IonLabel>
        </div>
        <div className="ion-padding">
          <p className="text-muted fs-4">Link giới thiệu của bạn</p>
          <div className="ion-padding bg-white text-center rounded">
            <IonImg src={userData.qr} className="qr-code m-auto"/>
            <div className="mb-1">
              <IonLabel onClick={copyShare} className="link-introduce" color="primary">{userData.url}</IonLabel>
            </div>
            <IonButton onClick={copyShare} color="primary" fill="clear" size="small">Copy</IonButton>
            <IonButton onClick={copyShare} color="primary" fill="clear" size="small">Share</IonButton>
          </div>
        </div>
        <div className="ion-padding">
          <p className="text-muted fs-4 mt-0">Bạn được chăm sóc bởi</p>
          <div className="ion-padding bg-white rounded d-flex ion-justify-content-start ion-align-items-center">
            <IonAvatar className="avatar-small">
              <IonIcon icon={userData.partner && userData.partner.avatar && userData.partner.avatar.id ? userData.partner.avatar.url : person} size="large" color="primary"/>
            </IonAvatar>
            <div className="ml-1">
              <IonLabel>{userData.partner.name} - {userData.partner.phone}</IonLabel><br />
              <IonLabel className="text-muted fs-5">{userData.partner.address}</IonLabel>
            </div>
          </div>
        </div>
        <div className="ion-padding">
          <p className="text-muted fs-4 mt-0">Quản lý tài khoản</p>
          <div className="ion-padding bg-white rounded">
            <IonItem onClick={() => { }} mode="ios" detail button lines="full" style={style.list_item}>
              <IonLabel>Phiếu giảm giá</IonLabel>
            </IonItem>
            <IonItem onClick={() => { }} mode="ios" detail button lines="full" style={style.list_item}>
              <IonLabel>Thông tin cá nhân</IonLabel>
            </IonItem>
            <IonItem onClick={logout} mode="ios" detail button lines="none" style={style.list_item}>
              <IonLabel>Đăng xuất</IonLabel>
            </IonItem>
          </div>
        </div>
        <div className="ion-padding">
          <p className="text-muted fs-4 mt-0">Hỗ trợ</p>
          <div className="ion-padding bg-white rounded">
            <IonItem onClick={() => { }} mode="ios" detail button lines="full" style={style.list_item}>
              <IonLabel>Điều khoản sử dụng</IonLabel>
            </IonItem>
            <IonItem onClick={() => { }} mode="ios" detail button lines="full" style={style.list_item}>
              <IonLabel>Trở thành Đối tác Mio</IonLabel>
            </IonItem>
            <IonItem onClick={() => { }} mode="ios" detail button lines="none" style={style.list_item}>
              <IonLabel>Trợ giúp</IonLabel>
            </IonItem>
          </div>
        </div>
        <div className="ion-padding d-flex ion-justify-content-between">
          <p className="text-muted fs-4 ion-no-margin">Phiên bản 1.0.0</p>
          <p className="text-muted fs-4 ion-no-margin">Phát triển bởi: <a href="https://www.cnnet.vn" target="_blank">CNNet.vn</a></p>
        </div>
      </IonContent>
      ) : null}
    </IonPage>
  );

  function logout() {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('accessTokenExpiresAt')
    localStorage.removeItem('accessTokenType')
    localStorage.removeItem('userData')
    // localStorage.removeItem('cart');
    setIsLogin(false);
    setTimeout(() => {
      history.push('/home')
    }, 250)
  }

  function copyShare() {
    navigator.clipboard.writeText(userData.url);
    presentToast({message: 'Copy link thành công', buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'success', mode: 'ios'});
  }
};

export default Account;

const style = {
  list_item: { '--padding-start': 0, '--background': 'none', '--background-hover': 'none', '--color-hover': 'var(--ion-color-primary)' }
}