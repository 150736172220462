import { IonContent, IonHeader, IonPage, IonButtons, IonToolbar, IonButton, IonIcon, IonBackButton, IonTitle } from '@ionic/react';
import { home } from 'ionicons/icons';

const NotFound: React.FC = () => {
  return (
    <IonPage>
      <IonHeader translucent={true} mode="ios">
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/home" mode="md"/>
        </IonButtons>
        <IonTitle>404 - Không tìm thấy</IonTitle>
      </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="d-flex h-100 flex-column ion-align-items-center ion-justify-content-center">
            <IonButton color="primary" href="/">
                <IonIcon slot="start" icon={home} />
                Về trang chủ
            </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NotFound;
