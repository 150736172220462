export function formatNumber(amount: number) {
    let decimalCount = 0, decimal = ".", thousands = ","
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(Math.abs(Number(amount) || 0).toFixed(decimalCount));
        let j = (i.toString().length > 3) ? i.toString().length % 3 : 0;

        return negativeSign + (j ? i.toString().substr(0, j) + thousands : '') + i.toString().substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};


export function calPercent(amount: number, amount_root: number) {
    return Math.round((amount / amount_root) * 100)
};

export function calCartPriceItem(amount: number, qty: number) {
    return Math.round(amount * qty)
};

export function getIndexArrByKey(arr: Array<any>, key: string, value: number) {
    if(arr) {
        for (var i=0; i < arr.length; i++) {
            if (arr[i][key] === value) {
                return i;
            }
        }
    }
    return -1;
}

export function getLastName(name: string) {
    return name.split(' ').slice(-1).join(' ')
}