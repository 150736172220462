import React, { useEffect } from "react";
import { RouteComponentProps, useLocation } from 'react-router-dom';
import $http from '../../libs/axios';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonInput,
  IonItem,
  IonIcon,
  IonNote,
  IonModal,
  useIonToast,
  useIonLoading
} from '@ionic/react';
import { locationOutline, peopleOutline, personOutline, phonePortraitOutline } from "ionicons/icons";
import ReactCodeInput from 'react-verification-code-input';

// Context
import { UIContext } from "../../context/ui";
import { StoreContext } from "../../context/store";


const Auth: React.FC<RouteComponentProps> = ({history}) => {
  let location = useLocation()
  const [auth, setAuth] = React.useState('login');
  const [showAuthOTP, setShowAuthOTP] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const [name, setName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [partnerId, setPartnerId] = React.useState('');
  const [otp, setOTP] = React.useState('');
  const [otpTime, setOTPTime] = React.useState(0);
  let intervalOTP: any = null

  const { setShowTabs } = React.useContext(UIContext);
  const { isLogin, setIsLogin } = React.useContext(StoreContext);
  useEffect(() => {
    setShowTabs(false);
    return () => {
      setShowTabs(isLogin);
    };
  }, [location]);

  const [presentToast, dismissToast] = useIonToast()
  const [presentLoading, dismissLoading] = useIonLoading()

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar  color="primary" style={{'--border-color': 'var(--ion-color-primary)'}}>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" mode="md"/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <img src="/assets/images/auth/banner.png" />
        <IonSegment value={auth} onIonChange={e => { setAuth(e.detail.value || 'login') }} mode="md">
          <IonSegmentButton value="login">
            <IonLabel>Đăng nhập</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="register">
            <IonLabel>&nbsp;&nbsp;Đăng ký&nbsp;&nbsp;&nbsp;</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <div className="ion-padding">
          <div className={auth == "login" ? '' : 'd-none'}>
            <IonItem className="ion-margin-bottom">
              <IonIcon icon={phonePortraitOutline} slot="start" color="primary" size="small"/>
              <IonInput value={phone} placeholder="Số điện thoại" onIonChange={e => {setPhone(e.detail.value!)}} type="tel" maxlength={10} />
            </IonItem>
            <IonButton onClick={() => {sendOTP()}} color="primary" mode="ios" expand="block">Đăng nhập</IonButton>
          </div>
          <div className={auth == "register" ? '' : 'd-none'}>
            <IonItem className="ion-margin-bottom">
              <IonIcon icon={phonePortraitOutline} slot="start" color="primary" size="small"/>
              <IonInput value={phone} placeholder="Số điện thoại" onIonChange={e => {setPhone(e.detail.value!)}} type="tel"></IonInput>
            </IonItem>
            <IonItem className="ion-margin-bottom">
              <IonIcon icon={personOutline} slot="start" color="primary" size="small"/>
              <IonInput value={name} placeholder="Họ và tên" onIonChange={e => {setName(e.detail.value!)}} type="text"></IonInput>
            </IonItem>
            <IonItem className="ion-margin-bottom">
              <IonIcon icon={locationOutline} slot="start" color="primary" size="small"/>
              <IonInput value={address} placeholder="Địa chỉ" onIonChange={e => {setAddress(e.detail.value!)}} type="text"></IonInput>
            </IonItem>
            <IonItem className="ion-margin-bottom">
              <IonIcon icon={peopleOutline} slot="start" color="primary" size="small"/>
              <IonInput value={partnerId} placeholder="ID người giới thiệu (nếu có)" onIonChange={e => {setPartnerId(e.detail.value!)}} type="text"></IonInput>
            </IonItem>
            <IonButton onClick={() => {sendOTP()}} color="primary" mode="ios" expand="block">Đăng ký</IonButton>
            <div className="ion-margin-top">
              <IonNote className="fs-4">*ID người giới thiệu là số điện thoại của người giới thiệu gom đơn trong công ty hoặc khu vực của bạn, nếu không có hãy để trống.</IonNote>
            </div>
          </div>

        </div>
      </IonContent>
      <IonModal
        isOpen={showAuthOTP}
        onDidDismiss={() => setShowAuthOTP(false)}
        cssClass='auth-otp-modal'
        mode="md"
      >
        <div className="ion-padding bg-white rounded h-100 d-flex flex-column ion-justify-content-between">
          <p className="fs-3 mt-0">Nhập mã xác thực được gửi đến số điện thoại {phone}</p>
          <ReactCodeInput values={[]} fields={4} onChange={(e) => {setOTP(e)}} fieldWidth={60} type="number" autoFocus={true} className="m-auto code-verification"/>
          <div className="ion-margin-top text-center">
            <IonButton onClick={() => {setShowAuthOTP(false)}} color="medium" fill="outline" mode="ios">Đóng</IonButton>
            <IonButton onClick={() => {submit()}} disabled={!otp || otp.length != 4} color="primary" mode="ios">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hoàn tất&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</IonButton>
          </div>
          {otpTime == 0 ? (
            <p className="fs-3 text-muted mb-0">Không nhận được mã xác thực <IonNote onClick={sendOTP} className="fs-3" color="warning">Yêu cầu lại</IonNote></p>
          ) : (
            <p className="fs-4 mb-0 text-muted">Xác thực chỉ hiệu lực trong vòng 120s, còn lại {otpTime}s</p>
          )}
        </div>
      </IonModal>
    </IonPage>
  );

  function sendOTP() {
    try {
      switch (auth) {
        case 'login':
          if(!phone) {
            throw 'Số điện thoại không được trống'
          }
          if(otpTime == 0) {
            presentLoading({message: 'Vui lòng chờ...', mode: 'ios', translucent: true});
            $http.post('/check/phone', {phone: phone, type: 'login', otp: true}).then(response => {
              setTimeout(() => {
                dismissLoading()
                setShowAuthOTP(true)
                hourglassOTP()
              }, 200);
            }).catch(error => {
              dismissLoading()
              presentToast({message: error.response ? error.response.data.message : 'Đã có lỗi kết nối', buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'warning', mode: 'ios', position: 'top'});
            });
          } else {
            setShowAuthOTP(true);
            dismissLoading()
          }
          break;
        case 'register':
          if(!phone) throw 'Số điện thoại không được trống'
          if(!name) throw 'Họ và tên không được trống'
          if(!address) throw 'Địa chỉ không được trống'
          if(otpTime == 0) {
            presentLoading({message: 'Vui lòng chờ...', mode: 'ios', translucent: true});
            $http.post('/check/phone', {phone: phone, type: 'register', otp: true}).then(response => {
              setTimeout(() => {
                dismissLoading()
                setShowAuthOTP(true)
                hourglassOTP()
              }, 200);
            }).catch(error => {
              dismissLoading()
              presentToast({message: error.response ? error.response.data.message : 'Đã có lỗi kết nối', buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'warning', mode: 'ios', position: 'top'});
            });
          } else {
            dismissLoading()
            setShowAuthOTP(true)
          }
          break;

        default:
          throw 'Lỗi hệ thống'
          break;
      }
    } catch (error) {
      presentToast({message: error, buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'warning', mode: 'ios', position: 'top'});
    }
  }

  function hourglassOTP() {
    clearInterval(intervalOTP)
    let otpTimeTemp = 120
    intervalOTP = setInterval(() => {
      if(otpTimeTemp >  1) {
        otpTimeTemp = otpTimeTemp - 1
        setOTPTime(otpTimeTemp)
      } else {
        otpTimeTemp = 0
        setOTPTime(0)
        clearInterval(intervalOTP)
      }
    }, 1000)
  }

  function submit() {
    if(auth == 'login') login()
    if(auth == 'register') register()
  }
  function login() {
    presentLoading({message: 'Vui lòng chờ...', mode: 'ios', translucent: true});
    $http.post('/login', {phone: phone, otp: otp, 'client_id': 2, 'client_secret': 'Y3iCbNZBXLGAQRd9YnA70hiPBxqsfo8GokvCyOh1'}).then(response => {
      $http.defaults.headers.Authorization = `Bearer ${response.data.token.access_token}`
      localStorage.setItem('accessToken', response.data.token.access_token);
      localStorage.setItem('accessTokenExpiresAt', response.data.token.expires_at);
      localStorage.setItem('accessTokenType', response.data.token.token_type);
      localStorage.setItem('userData', JSON.stringify(response.data.user));
      presentToast({message: 'Đăng nhập thành công', buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'success', mode: 'ios', position: 'top'});
      setShowAuthOTP(false)
      setIsLogin(true)
      setTimeout(() => {
        dismissLoading()
        history.go(-1)
      }, 1000);
    }).catch(error => {
      dismissLoading()
      presentToast({message: error.response ? error.response.data.message : 'Đã có lỗi kết nối', buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'warning', mode: 'ios', position: 'top'})
    });
  };
  function register() {
    presentLoading({message: 'Vui lòng chờ...', mode: 'ios', translucent: true});
    $http.post('/register', {name: name, phone: phone, address: address, partner_id: partnerId, otp: otp, 'client_id': 2, 'client_secret': 'Y3iCbNZBXLGAQRd9YnA70hiPBxqsfo8GokvCyOh1'}).then(response => {
      localStorage.setItem('accessToken', response.data.token.access_token);
      localStorage.setItem('accessTokenExpiresAt', response.data.token.expires_at);
      localStorage.setItem('accessTokenType', response.data.token.token_type);
      localStorage.setItem('userData', JSON.stringify(response.data.user));
      presentToast({message: 'Đăng ký thành công', buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'success', mode: 'ios', position: 'top'});
      setShowAuthOTP(false)
      setIsLogin(true)
      setTimeout(() => {
        dismissLoading()
        history.go(-1)
      }, 250);
    }).catch(error => {
      dismissLoading()
      presentToast({message: error.response ? error.response.data.message : 'Đã có lỗi kết nối', buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'warning', mode: 'ios', position: 'top'});
    });
  };
};

export default Auth;
