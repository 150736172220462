import React from "react";
import { Link } from 'react-router-dom';
import {
  IonBadge,
  IonImg,
  IonButton,
  IonIcon,
  IonNote,
  IonCol,
  useIonToast,
  useIonLoading
} from '@ionic/react';
import { cart } from 'ionicons/icons';

// Libs
import $http from '../../libs/axios'

// Context
import { UIContext } from "../../context/ui";
import { StoreContext } from "../../context/store"

// Models
import { Product } from '../../models/product'

// Services
import { formatNumber, calPercent } from '../../services/utils'

interface data {
  record : Product
}

const ProductItem: React.FC<data> = ({ record }) => {
  const { setShowQuickView } = React.useContext(UIContext);
  const { setProduct } = React.useContext(StoreContext);

  const [presentToast, dismissToast] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();

  return (
    <IonCol size="6" style={{ '--ion-grid-column-padding': 0, padding: '0 5px', marginTop: '10px'}}>
      <div className="product-item rounded">
        <Link to={'/product/' + record.id}>
          <div className="product-item-media">
            <IonImg src={ record.avatar.url } className="product-item-img"/>
            {record.market_price != record.price ? (
              <IonBadge color="primary" className="percent">{calPercent(record.price, record.market_price)}%</IonBadge>
            ): null }
          </div>
        </Link>

        <div className="product-item-info">
          <Link to={'/product/' + record.id}>
            <h2 className="product-item-name">{ record.name }</h2>
            <p className="product-item-note ion-no-margin">{ record.log_time }</p>
          </Link>
          <div className="product-item-car d-flex ion-justify-content-between">
            <div className="d-flex product-item-price">
              <IonNote color="primary" className="price" mode="ios">{ formatNumber(record.price) } ₫</IonNote>
              <IonNote mode="ios" className="price-root">{ formatNumber(record.market_price) } ₫</IonNote>
            </div>
            <IonButton onClick={() => { quickView() }} color="primary" size="small" mode="ios"><IonIcon icon={cart} /></IonButton>
          </div>
        </div>
      </div>
    </IonCol>
  );

  function quickView() {
    presentLoading({message: 'Vui lòng chờ...', mode: 'ios', translucent: true});
    $http.get(`/product/${record.id}`).then(response => {
      setTimeout(() => {
        setProduct(response.data)
        dismissLoading()
        setShowQuickView(true)
      }, 300)
    }).catch(error => {
      dismissLoading()
      presentToast({message: error.response ? error.response.data.message : 'Đã có lỗi kết nối', buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'warning', mode: 'ios'});
    })
  }
};

export default ProductItem;
