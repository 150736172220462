import React from "react";
import {
  IonButton,
  IonIcon,
  IonNote,
} from '@ionic/react';
import { remove, add, close } from "ionicons/icons";

// Context
import { StoreContext } from "../../context/store"

// Models
import { Cart } from '../../models/cart'

// Services
import { formatNumber } from '../../services/utils'

interface data {
  index: number,
  record : Cart
  update: Function,
  source: number
}

const CartItem: React.FC<data> = ({ index, record, update, source }) => {
  const { updateCart, removeCartItem } = React.useContext(StoreContext);
  const [qty, setQty] = React.useState(0)

  return (
    <div className="cart-item ion-padding-bottom ion-margin-bottom"  onLoad={() => {setQty(record.qty)}}>
      <div className="d-flex ion-justify-content-start mb-1">
        <img src={record.product.avatar.url} className="cart-item-img rounded"/>
        <div className="d-flex flex-column ml-1 w-100 ion-justify-content-between">
          <div className="d-flex ion-justify-content-between">
              <p className="fs-4 ion-no-margin">{record.product.name}</p>
              <p className="fs-4 ion-no-margin text-muted">{formatNumber(record.product.price * record.qty)} ₫</p>
          </div>
          <div className="d-flex ion-justify-content-between">
              <div className="d-flex ion-justify-content-end ion-align-items-center">
              <IonButton onClick={() => {updateCart(index, record.qty - 1, source); setQty(record.qty); update()}} color="light" mode="ios" size="small" fill="clear">
                  <IonIcon slot="icon-only" size="small" icon={remove} color="dark"/>
              </IonButton>
              <IonNote mode="ios" className="my-1 fs-4" color="dark">{qty}</IonNote>
              <IonButton onClick={() => {updateCart(index, record.qty + 1, source); setQty(record.qty); update()}} color="light" mode="ios" size="small" fill="clear">
                  <IonIcon slot="icon-only" size="small" icon={add} color="dark"/>
              </IonButton>
              <IonNote mode="ios" className="ion-margin-horizontal text-muted fs-4">{record.product.unit}</IonNote>
              </div>
              <IonButton onClick={() => {removeCartItem(index, source); update()}} color="light" mode="ios" size="small">
                <IonIcon slot="icon-only" size="small" icon={close} />
              </IonButton>
          </div>
        </div>
      </div>
      <p className="fs-5 ion-no-margin text-muted">{record.product.log_time}</p>
    </div>
  );


};

export default CartItem;
