import React, { useEffect } from "react";
import { RouteComponentProps } from 'react-router-dom';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonIcon,
  IonNote,
  IonBadge,
  IonButton,
  IonTextarea,
  IonAvatar,
  useIonToast,
  useIonLoading
} from '@ionic/react';
import { person } from "ionicons/icons"
import Countdown, { zeroPad } from 'react-countdown'

// Libs
import $http from '../../libs/axios'

// Components
import CartProgress from "../../components/cart/progress"
import CartItem from "../../components/cart/item"
import Empty from "../../components/empty/index"

// Context
import { UIContext } from "../../context/ui";
import { StoreContext } from "../../context/store"

// Models
import { Cart as CartModel } from '../../models/cart'

// Services
import { formatNumber, getLastName } from '../../services/utils'

const Cart: React.FC<RouteComponentProps> = ({history}) => {
  const { isLogin, setIsLogin } = React.useContext(StoreContext);
  const userData = JSON.parse(localStorage.getItem('userData')!);
  const { setShowTabs } = React.useContext(UIContext);
  const { cart, setCart, removeCartSource } = React.useContext(StoreContext);
  const [source, setSource] = React.useState(0)
  const [qty, setQty] = React.useState(0)
  const [total, setTotal] = React.useState(0)
  const [note, setNote] = React.useState('')
  const [flagSubmit, setFlagSubmit] = React.useState(false)
  const today = new Date
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  const timeDown = today.getHours() < 21 ? today.setHours(21, 0, 0) :  tomorrow.setHours(21, 0, 0)
  
  useEffect(() => {
    setShowTabs(false)
    setQty(cart[source].length)
    calcTotal(cart[source])
    return () => {
      setShowTabs(true);
    };
  }, [])

  const [presentToast, dismissToast] = useIonToast()
  const [presentLoading, dismissLoading] = useIonLoading();

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" mode="md"/>
          </IonButtons>
          <IonSegment value={source.toString()} onIonChange={e => {setSource(parseInt(e.detail.value!))}}>
            <IonSegmentButton value="0">
              <IonLabel>Giỏ hàng Mio ({cart[0].length})</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="1">
              <IonLabel>Giỏ hàng {isLogin ? getLastName(userData.name) : 'Partner'} ({cart[1].length})</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      {cart[source].length ? (
      <IonContent fullscreen slot="fixed">
        <div className="cart">
          <div className="cart-inner">
            <div className="ion-padding bg-white">
              <p className="mt-0 fs-4 text-muted">Nhóm gom đơn cho {isLogin ? getLastName(userData.name) : 'Partner'} {total >= 200000 ? (<span>đã đạt mốc <IonNote color="primary" className="fw-bold">Tối thiểu</IonNote></span>) : null}</p>
              <CartProgress total={total} max={200000}/>
              <div className="d-flex ion-justify-content-between">
                <p className="mt-0 fs-4 text-muted">Phí giao hàng: <IonNote color="dark" className="fw-bold">0 ₫</IonNote></p>
                <p className="mt-0 fs-4 text-muted mb-0">
                  <span>Thời gian gom đơn còn</span>
                  <Countdown 
                    date={timeDown}
                    renderer={({ hours, minutes, seconds }) => {
                      return <span className="ml-1 fs-4">{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
                    }}
                  />
                </p>
              </div>
              <p className="mt-0 mb-0 fs-5 text-muted"><i>Các đơn sẽ tự động được xử lí tiếp nhận sau khi hết thời gian gom đơn</i></p>
            </div>
            <p className="text-muted ion-no-margin ion-padding fs-4">Đơn hàng của bạn ({qty} món)</p>
            <div className="ion-padding bg-white ion-margin-bottom">
              {cart[source].map((item: CartModel, index: number) => (
                <CartItem key={(index).toString()} record={item} index={index} update={update} source={source}/>
              ))}
              <div className="d-flex ion-justify-content-between ion-margin-bottom">
                <IonNote className="fs-4 text-muted">Thành tiền</IonNote>
                <IonNote className="fs-3">{formatNumber(total)} ₫</IonNote>
              </div>
              <div className="d-flex ion-justify-content-between">
                <IonNote className="fs-4 text-muted">Giảm giá</IonNote>
                <IonNote className="fs-4" color="tertiary">0 ₫</IonNote>
              </div>
              <p className="mb-1 fs-4">Ghi chú</p>
              <IonTextarea value={note} onIonChange={e => {setNote(e.detail.value!)}} className="py-2 fs-4" placeholder="Thời gian đặt hàng, giao nhận hàng, ..." rows={3} mode="ios" style={{ '--background': '#F9F9F9' }}></IonTextarea>
            </div>
            {isLogin ? (
            <>
              <div className="bg-white ion-padding ion-margin-bottom rounded">
                <p className="ion-no-margin text-muted ion-margin-bottom">Thông tin nhận hàng</p>
                <div className="ion-margin-bottom">
                  <p className="ion-no-margin text-muted fs-4 d-flex ion-justify-content-between ion-align-items-center">
                    <span>Người nhận</span>
                    <span>{userData.partner.name}</span>
                  </p>
                  <p className="ion-no-margin text-muted fs-4 d-flex ion-justify-content-between ion-align-items-center">
                    <span>Điện thoại</span>
                    <span>{userData.partner.phone}</span>
                  </p>
                  <p className="ion-no-margin text-muted fs-4 d-flex ion-justify-content-between ion-align-items-center">
                    <span>Địa chỉ</span>
                    <span>{userData.partner.address}</span>
                  </p>
                </div>
                <p className="ion-no-margin fs-5">{userData.partner.name} sẽ liên lạc và giao đến cho bạn sau khi nhận hàng</p>
              </div>

              <div className="ion-padding bg-white ion-margin-bottom">
                <p className="text-muted fs-4 ion-no-margin">Bạn được chăm sóc bởi</p>
                <div className="ion-padding-top ion-padding-bottom bg-white rounded d-flex ion-justify-content-start ion-align-items-center">
                  <IonAvatar className="avatar-small">
                    <IonIcon icon={userData.partner && userData.partner.avatar && userData.partner.avatar.id ? userData.partner.avatar.url : person} size="large" color="primary"/>
                  </IonAvatar>
                  <div className="ml-2">
                    <IonLabel>{userData.partner.name} - {userData.partner.phone}</IonLabel><br />
                    <IonLabel className="text-muted fs-5">{userData.partner.address}</IonLabel>
                  </div>
                </div>
                <div className="d-flex ion-justify-content-between">
                  <p className="text-muted fs-4 ion-no-margin">Thời gian giao hàng</p>
                  <p className="fs-4 ion-no-margin">Tuỳ vào sản phẩm</p>
                </div>
              </div>
            </>
            ): null}
          </div>
        </div>
        <div slot="fixed" className="cart-bar ion-padding">
          <div className="d-flex ion-justify-content-between ion-margin-bottom">
            <IonNote>Tổng thanh toán</IonNote>
            <IonNote color="primary" className="fs-3 fw-bold">{formatNumber(total)} ₫</IonNote>
          </div>
          <IonButton onClick={submit} expand="block" mode="ios">Đặt hàng Mio</IonButton>
        </div>
      </IonContent>
      ) : (
        <IonContent fullscreen>
          <Empty note="Giỏ hàng trống." />
        </IonContent>
      )}
    </IonPage>
  );

  function update() {
    setCart(cart)
    setQty(cart.length)
    calcTotal(cart[source])
  }

  function calcTotal(records : Array<CartModel>) {
    let totalPrice = 0
    records.forEach((item) => {
      totalPrice += item.qty * item.product.price
    })
    setTotal(totalPrice)
  }

  async function submit() {
    try {
      if(!isLogin) {
        setTimeout(() => {
          history.push('/auth')
        }, 250)
        throw "Bạn cần phải đăng nhập để tiếp tục đặt hàng."
      }
      if(cart[source].length == 0) {
        throw "Giỏ hàng của Bạn đang trống, Bạn thể đặt hàng."
      }
      if(!flagSubmit) {
        setFlagSubmit(true)
        presentLoading({message: 'Vui lòng chờ...', mode: 'ios', translucent: true});
        await $http.post('/order', {sub_total:total, total: total, note: note, source: source, cart: cart[source]}).then(response => {
          presentToast({message: response.data.message, buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'success', mode: 'ios', position: 'top'});
          setTimeout(() => {
            removeCartSource(source)
            dismissLoading()
            history.push('/home')
          }, 1000);
        }).catch(error => {
          setTimeout(() => {
            dismissLoading()
          }, 300);
          presentToast({message: error.response ? error.response.data.message : 'Đã có lỗi kết nối', buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'warning', mode: 'ios', position: 'top'});
          setFlagSubmit(false)
        })
      }
    } catch (error) {
      presentToast({message: error, buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3500, color: 'warning', mode: 'ios', position: 'top'});
    }
  }
};

export default Cart;
