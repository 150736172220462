import React from "react";
import { useIonToast } from '@ionic/react';

// Model
import { Cart } from '../models/cart'
import { ProductDetail } from '../models/product'

// Services
import { getIndexArrByKey } from '../services/utils'


export const StoreContext = React.createContext<any>(undefined);
export const StoreProvider: React.FC = ({ children }) => {
  const [presentToast, dismissToast] = useIonToast();

  // the reactive values
  const [product, setProduct] = React.useState({id: 0, cart: {qty: 0, qty_from: 0, qty_to: 0}});
  const [cart, setCart] = React.useState(localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')!) : [Array<Cart>(), Array<Cart>()]);
  const [isLogin, setIsLogin] = React.useState(localStorage.getItem('accessToken') ? true : false);

  function setProductCartQty(productDetail: ProductDetail, qty: number) {
    let productOrigin = productDetail;
    if(productOrigin.id && qty >= productOrigin.cart.qty_from && qty <= productOrigin.cart.qty_to) {
      productOrigin.cart.qty = qty
      setProduct(productOrigin)
    }
  }

  function addToCart(value: Cart, source: number) {
    const index = getIndexArrByKey(cart[source], 'product_id', value.product_id)
    if(index === -1) {
      cart[source].push(value)
    } else {
      cart[source][index] = value
    }
    setCart(cart)
    localStorage.setItem('cart', JSON.stringify(cart))
    presentToast({message: `Thêm ${value.product.name}  vào giỏ hàng thành công.`, buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'success', mode: 'ios', position: 'top'})
  }

  function updateCart(index: number, qty: number, source: number) {
    const cartItem = cart[source][index]
    if(cartItem && qty >= cartItem.product.qty_from && qty <= cartItem.product.qty_to) {
      cart[source][index].qty = qty
      setCart(cart)
    }
    localStorage.setItem('cart', JSON.stringify(cart))
  }

  function removeCartItem(index: number, source: number) {
    cart[source].splice(index, 1)
    setCart(cart)
    localStorage.setItem('cart', JSON.stringify(cart))
  }

  function removeCartSource(source: number) {
    cart[source] = []
    setCart(cart)
    localStorage.setItem('cart', JSON.stringify(cart))
  }

  let state = {
    product,
    setProduct,
    setProductCartQty,
    cart,
    setCart,
    addToCart,
    updateCart,
    removeCartItem,
    removeCartSource,
    isLogin,
    setIsLogin
  };

   // wrap the application in the provider with the initialized context
  return <StoreContext.Provider value={state}>{children}</StoreContext.Provider>;

}

export default StoreContext;
