import React, { useEffect } from "react"
import { useLocation } from 'react-router-dom'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonAvatar,
  IonIcon,
  IonRow,
  IonCol,
  IonNote,
  useIonToast
} from '@ionic/react';
import { person, phonePortraitOutline, locationOutline, fileTrayOutline } from 'ionicons/icons';

// Libs
import $http from '../../libs/axios'

// Context
import { StoreContext } from "../../context/store"

// Model
import { Partner } from '../../models/partner'

// Services
import { formatNumber, getLastName } from '../../services/utils'

const Store: React.FC = () => {
  let location = useLocation()
  const user = JSON.parse(localStorage.getItem('userData')!)
  const { isLogin, cart } = React.useContext(StoreContext)
  const [partner, setPartner] = React.useState(Object)
  const [product, setProduct] = React.useState([])
  useEffect(() => {
    const getRecords = async () => {
      if(isLogin) {
        await getRecordPartner()
        await getRecordsProduct()
      }
    }
    getRecords()
    return () => {};
  }, []);

  const [presentToast, dismissToast] = useIonToast()

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonTitle>Gian hàng của {isLogin && partner.id ? getLastName(partner.name) : 'Partner'}</IonTitle>
        </IonToolbar>
      </IonHeader>
      {isLogin && partner.id ? (
        <IonContent fullscreen>
          <div className="ion-padding bg-white">
            <IonRow>
              <IonCol>
                <IonAvatar className="avatar">
                  <IonIcon icon={partner.avatar && partner.avatar.id ? partner.avatar.url : person} size="large" color="primary" mode="ios"/>
                </IonAvatar>
              </IonCol>
              <IonCol className="ion-align-self-center">
                <h4 className="ion-no-margin">{product.length}</h4>
                <IonNote>Sản phẩm</IonNote>
              </IonCol>
              <IonCol className="ion-align-self-center">
                <h4 className="ion-no-margin">{partner.statistic.order}</h4>
                <IonNote>Lượt mua</IonNote>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <h2>{partner.name}</h2>
                <div>
                  <IonNote><IonIcon icon={phonePortraitOutline}/> {partner.phone}</IonNote>
                </div>
                <div>
                  <IonNote>
                    <IonIcon icon={locationOutline}/> {partner.address}
                  </IonNote>
                </div>
              </IonCol>
            </IonRow>
          </div>
          <div className="ion-padding ion-margin-top text-center">
            <div><IonIcon icon={fileTrayOutline} size="large" className="text-muted"/></div>
            <p className="text-muted ion-no-margin"><small>{getLastName(partner.name)} chưa đăng ký gian hàng riêng</small></p>
          </div>
        </IonContent>
      ) : null }
    </IonPage>
  );
  async function getRecordPartner() {
    await $http.get(`/partner/${user.partner.id}`).then(response => {
      setPartner(response.data)
    }).catch(error => {
      presentToast({message: error.response ? error.response.data.message : 'Đã có lỗi kết nối', buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'warning', mode: 'ios', position: 'top'});
    })
  }
  function getRecordsProduct() {
    
  }
};

export default Store;
