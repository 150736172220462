import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonModal,
  IonNote,
  IonButton,
  IonBadge
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { peopleCircle, documentText, home, person, people, remove, add, cart } from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Style */
import './assets/css/style.css';

// View
import Home from './views/home/index';
import Store from './views/store/index';
import Manage from './views/manage/index';
import Activity from './views/activity/index';
import Account from './views/account/index';
import ProductDetail from './views/product/detail';
import OrderDetail from './views/order/detail';
import Cart from './views/cart/index';
import Search from './views/search/index';
import Auth from './views/auth/index';
import NotFound from './views/error/404';

// Context
import { UIContext } from "./context/ui";
import { StoreContext } from "./context/store"

// Services
import { formatNumber, calPercent } from './services/utils'

// Models
import { ProductOptions } from './models/product'

const App: React.FC = () => {
  const { showTabs, showQuickView, setShowQuickView } = React.useContext(UIContext)
  const { product, setProductCartQty, cart, addToCart } = React.useContext(StoreContext);
  const [qty, setQty] = React.useState(0)
  let tabBarStyle = showTabs ? { '--background': 'var(--ion-color-primary-contrast)', 'display': "" } : { '--background': 'var(--ion-color-primary-contrast)', 'display': "none" };
  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/"><Redirect to="/home" /></Route>
            <Route path="/home" component={Home} exact={true}/>
            <Route path="/store" component={Store} exact={true}/>
            <Route path="/manage" component={Manage} exact={true}/>
            <Route path="/activity" component={Activity} exact={true}/>
            <Route path="/account" component={Account} exact={true}/>
            <Route path="/product/:id" component={ProductDetail}/>
            <Route path="/order/:id" component={OrderDetail}/>
            <Route path="/cart" component={Cart}/>
            <Route path="/search" component={Search}/>
            <Route path="/auth" component={Auth}/>
            <Route component={NotFound} />
          </IonRouterOutlet>
          <IonTabBar slot="bottom" style={tabBarStyle} mode="md">
            <IonTabButton tab="home" href="/home">
              <IonIcon icon={home} />
              <IonLabel>Mio</IonLabel>
              {cart[0] && cart[0].length ? (
                <IonBadge color="primary" mode="ios">{cart[0].length}</IonBadge>
              ) : null}
            </IonTabButton>
            <IonTabButton tab="store" href="/store">
              <IonIcon icon={peopleCircle} />
              <IonLabel>Sơn Shop</IonLabel>
            </IonTabButton>
            <IonTabButton tab="manage" href="/manage">
              <IonIcon icon={people} />
              <IonLabel>Quản lý</IonLabel>
            </IonTabButton>
            <IonTabButton tab="activity" href="/activity">
              <IonIcon icon={documentText} />
              <IonLabel>Đơn hàng</IonLabel>
            </IonTabButton>
            <IonTabButton tab="account" href="/account">
              <IonIcon icon={person} />
              <IonLabel>Tài khoản</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
      <IonModal
        isOpen={showQuickView}
        onDidDismiss={() => setShowQuickView(false)}
        cssClass='product-quickview-modal'
        mode="md"
      >
        {product && product.id ? (
          <div className="product-quickview ion-padding" onLoad={() => {setQty(product.cart.qty)}}>
            <div className="d-flex ion-justify-content-start">
              <div className="product-quickview-media">
                <img src={ product.avatar.url } className="product-quickview-img rounded"/>
              </div>
              <div className="product-quickview-info ion-padding-horizontal">
                <h5 className="ion-no-margin ion-margin-bottom product-quickview-name">{product.name}</h5>
                <div className="product-quickview-price d-flex flex-column">
                  {product.market_price != product.price ? (
                    <IonNote mode="ios" className="price-root">{formatNumber(product.market_price)} ₫</IonNote>
                  ): null}
                  <IonNote color="primary" className="price" mode="ios">{formatNumber(product.price)} ₫/{product.unit} {product.market_price != product.price ? (`(-${calPercent(product.price, product.market_price)}%)`) : null}</IonNote>
                </div>
              </div>
            </div>
            <div className="product-quickview-option ion-margin-bottom">
              {product.options.map((option: ProductOptions, option_index: number) => (
                <div key={(option_index).toString()}>
                  <p className="ion-no-margin ion-margin-top">{option.name}:</p>
                  {option.children.map((children, children_index) => (
                    <IonButton key={(children_index).toString()} color="warning" mode="ios" size="small">{children.name}</IonButton>
                  ))}
                </div>
              ))}
            </div>
            <div className="d-flex ion-justify-content-between ion-align-items-center">
              <p className="ion-no-margin">Số lượng ({product.unit})</p>
              <div className="d-flex ion-justify-content-end ion-align-items-center">
                <IonButton onClick={() => {setProductCartQty(product, product.cart.qty - 1); setQty(product.cart.qty); }} color="light" mode="ios">
                  <IonIcon slot="icon-only" size="small" icon={remove} />
                </IonButton>
                <IonNote mode="ios" className="ion-margin-horizontal" color="dark">{product.cart.qty}</IonNote>
                <IonButton onClick={() => {setProductCartQty(product, product.cart.qty + 1); setQty(product.cart.qty); }} color="light" mode="ios">
                  <IonIcon slot="icon-only" size="small" icon={add} />
                </IonButton>
              </div>
            </div>
            <IonButton onClick={() => {addToCart({qty: qty, product_id: product.id, product: product.cart}, product.source); setTimeout(() => {setShowQuickView(false)}, 100);}} color="primary" mode="ios" expand="block">
              <div className="d-flex ion-justify-content-between ion-align-items-center w-100">
                <span>
                  <IonIcon slot="icon-only" size="small" icon={cart} className="icon-center"/> Thêm vào giỏ hàng
                </span>
                <span>{formatNumber(qty * product.cart.price)} ₫</span>
              </div>
            </IonButton>
          </div>
        ) : null}
      </IonModal>
    </IonApp>
  );
};

export default App;
