import React, { useEffect, useRef } from "react";
import { useParams, RouteComponentProps } from 'react-router-dom';
import {
  IonContent,
  IonPage,
  IonBackButton,
  IonButtons,
  IonNote,
  IonButton,
  IonIcon,
  IonSlides,
  IonSlide,
  IonGrid,
  IonRow,
  IonBadge,
  useIonToast,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonImg
} from '@ionic/react';
import { remove, add, cart, home } from 'ionicons/icons';
import { RefresherEventDetail } from '@ionic/core';

// Libs
import $http from '../../../libs/axios'

// Components
import ProductItem from '../../../components/product/item';

// Models
import { ProductDetail, ProductOptions } from '../../../models/product'

// Context
import { UIContext } from "../../../context/ui";
import { StoreContext } from "../../../context/store"

// Services
import { formatNumber, calPercent } from '../../../services/utils'

const slideOpts = {
  slidesPerView: 1,
  centeredSlides: true,
  spaceBetween: 15,
  loop: true,
  speed: 400,
};

const Product: React.FC<RouteComponentProps> = ({history}) => {
  let { id } = useParams<{id: string}>()
  const [product, setProduct] = React.useState(Object)
  const [recordsProduct, setRecordsProduct] = React.useState(Array<ProductDetail>())
  const [qty, setQty] = React.useState(0)
  const [disableInfiniteScroll, setDisableInfiniteScroll] = React.useState(true)
  const { setShowTabs } = React.useContext(UIContext)
  const { isLogin, setProductCartQty, addToCart } = React.useContext(StoreContext)
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useEffect(() => {
    contentRef.current && contentRef.current.scrollToTop();
    setShowTabs(false)
    const getRecords = async () => {
      await getRecordProduct()
      await getRecordsProduct()
    }
    getRecords()
    return () => {
      setShowTabs(isLogin)
    };
  }, [id]);

  const [presentToast, dismissToast] = useIonToast();
  return (
    <IonPage>
      <IonContent fullscreen className="product-detail" ref={contentRef} scrollEvents={true}>
        {product && product.id ? (
          <div onLoad={() => {setQty(product.cart.qty)}}>
            <div className="position-relative">
              <div className="product-detail-toolbar d-flex ion-justify-content-between w-100 p-2">
                <IonButtons slot="start">
                  <IonBackButton defaultHref="/home" mode="md" className="btn-back"/>
                </IonButtons>
                <IonButton onClick={() => {history.push('/home')}} mode="ios" fill="clear">
                  <IonIcon icon={home} />
                </IonButton>
              </div>

              {product.info.gallery && product.info.gallery.lenght ? (
                <IonSlides pager={true} options={slideOpts}>
                  {product.info.gallery.map((slider: {url: string}, index: number) => (
                  <IonSlide key={(index).toString()}>
                    <img src={slider.url} alt={product.name}/>
                  </IonSlide>
                  ))}
                </IonSlides>
              ) : (
                <IonImg src={product.avatar.url} />
              )}
            </div>
            <div className="ion-padding bg-white">
              <h5 className="ion-no-margin ion-margin-bottom">{product.name}</h5>
              <div className="product-price d-flex flex-column">
                {product.market_price != product.price ? (
                  <IonNote mode="ios" className="price-root">{formatNumber(product.market_price)} ₫</IonNote>
                ): null}
                <IonNote color="primary" className="price" mode="ios">{formatNumber(product.price)} ₫/{product.unit} {product.market_price != product.price ? (`(-${calPercent(product.price, product.market_price)}%)`) : null}</IonNote>
              </div>
              <hr />
              <div className="product-option ion-margin-bottom">
                {product.options.map((option: ProductOptions, option_index: number) => (
                  <div key={(option_index).toString()}>
                    <p className="ion-no-margin ion-margin-top">{option.name}:</p>
                    {option.children.map((children, children_index) => (
                      <IonButton key={(children_index).toString()} color="warning" mode="ios" size="small">{children.name}</IonButton>
                    ))}
                  </div>
                ))}
              </div>
              <div className="d-flex ion-justify-content-between ion-align-items-center">
                <p className="ion-no-margin">Số lượng ({product.unit})</p>
                <div className="d-flex ion-justify-content-end ion-align-items-center">
                  <IonButton onClick={() => {setProductCartQty(product, product.cart.qty - 1); setQty(product.cart.qty); }} color="light" mode="ios">
                    <IonIcon slot="icon-only" size="small" icon={remove} />
                  </IonButton>
                  <IonNote mode="ios" className="ion-margin-horizontal" color="dark">{product.cart.qty}</IonNote>
                  <IonButton onClick={() => {setProductCartQty(product, product.cart.qty + 1); setQty(product.cart.qty); }} color="light" mode="ios">
                    <IonIcon slot="icon-only" size="small" icon={add} />
                  </IonButton>
                </div>
              </div>
              <IonButton onClick={() => {addToCart({qty: qty, product_id: product.id, product: product.cart}, product.source); setTimeout(() => {history.push('/home')}, 100);}} color="primary" mode="ios" expand="block">
                <div className="d-flex ion-justify-content-between ion-align-items-center w-100">
                  <span>
                    <IonIcon slot="icon-only" size="small" icon={cart} className="icon-center"/> Thêm vào giỏ hàng
                  </span>
                  <span>{formatNumber(qty * product.cart.price)} ₫</span>
                </div>
              </IonButton>
            </div>
            <div className="ion-padding bg-white ion-margin-top">
              <div className="ion-margin-bottom">
                <h5 className="ion-no-margin">{product.name}</h5>
                <IonNote className="fs-4">Xuất xứ: {product.origin}</IonNote>
                <div>
                  {product.info.tags.map((tag: {name: string}, index: number) => (
                    <IonBadge key={(index).toString()} color="warning" mode="ios" className="mr-1 mt-1">{tag.name}</IonBadge>
                  ))}
                </div>
              </div>
              <div>{product.info.content}</div>
            </div>
            <div className="bg-light">
              <div className="ion-padding pb-0">
                <h5 className="ion-no-margin">Sản phẩm tương tự</h5>
              </div>
              <IonGrid>
                <IonRow>
                  {recordsProduct.map(item => (
                    <ProductItem record={item} key={item.id.toString()}/>
                  ))}
                </IonRow>
              </IonGrid>
            </div>
          </div>
        ) : null}
      </IonContent>
    </IonPage>
  );

  async function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    await getRecordProduct()
    await getRecordsProduct()
    event.detail.complete();
  }

  async function getRecordProduct() {
    if(id) {
      setRecordsProduct([])
      await $http.get(`/product/${id}`).then(response => {
        setProduct(response.data)
        setQty(response.data.cart.qty)
        setDisableInfiniteScroll(false)
      }).catch(error => {
        presentToast({message: error.response ? error.response.data.message : 'Đã có lỗi kết nối', buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'warning', mode: 'ios'});
      })
    }
  }
  async function getRecordsProduct() {
    setDisableInfiniteScroll(true)
    if(id) {
      await $http.get(`/product/${id}/same`).then(response => {
        setRecordsProduct(response.data)
      }).catch(error => {
        presentToast({message: error.response ? error.response.data.message : 'Đã có lỗi kết nối', buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'warning', mode: 'ios'});
      })
    }
  }
};

export default Product;
