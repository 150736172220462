interface ContainerProps {
  note?: string;
}

const Empty: React.FC<ContainerProps> = ({ note }) => {
  return (
    <div className="container">
      <img src="/assets/images/default/empty.png" alt="" />
      <p className="text-muted ion-no-margin">{note}</p>
    </div>
  );
};

export default Empty;
