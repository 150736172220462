import React, { useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonNote,
  IonLabel,
  IonChip,
  IonIcon,
  IonThumbnail
} from '@ionic/react';
import { documentTextOutline, timeOutline } from 'ionicons/icons';

// Context
import { UIContext } from "../../../context/ui";

const OrderDetail: React.FC = () => {
  const { setShowTabs } = React.useContext(UIContext);
  useEffect(() => {
    setShowTabs(false);
    return () => {
      setShowTabs(true);
    };
  }, []);
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/activity" mode="md"/>
          </IonButtons>
          <IonTitle>Chi tiết đơn hàng</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="ion-padding">
          <div className="d-flex ion-justify-content-between ion-align-items-center bg-white ion-padding ion-margin-bottom rounded">
            <div>
                <div><IonIcon icon={documentTextOutline}/> LTHP01506132027004</div>
                <div><IonIcon icon={timeOutline}/> 15/06/2021 13:20</div>
            </div>
            <IonChip color="danger" className="ion-no-margin">Hủy</IonChip>
          </div>
          <div className="bg-white ion-padding ion-margin-bottom rounded">
            <p className="ion-no-margin text-muted ion-margin-bottom">Thông tin nhận hàng</p>
            <div className="d-flex ion-justify-content-between ion-align-items-center ion-margin-bottom">
              <p className="ion-no-margin text-muted fs-4">
                Người nhận<br />
                Điện thoại<br />
                Địa chỉ
              </p>
              <p className="ion-no-margin text-right fs-4">
                Cao Xuân Sơn<br />
                0932970983<br />
                154 Âu Dương Lân, P3, Q8, TP Hồ Chí Minh
              </p>
            </div>
            <p className="ion-no-margin fs-5">Cao Xuân Sơn sẽ liên lạc và giao đến cho bạn sau khi nhận hàng</p>
          </div>
          <div className="bg-white ion-padding ion-margin-bottom rounded">
            <div className="d-flex ion-justify-content-between">
              <div>
                <p className="text-muted mt-0">Thành tiền</p>
                <p className="text-muted mt-0">Giảm giá</p>
                <p className="text-muted ion-no-margin">Tổng thanh toán</p>
              </div>
              <div>
                <p className="text-right mt-0">28,500₫</p>
                <p className="text-right mt-0"><IonNote color="success">0₫</IonNote></p>
                <p className="text-right ion-no-margin"><IonNote color="primary" className="fs-2 fw-bold">28,500₫</IonNote></p>
              </div>
            </div>
          </div>
          <div className="bg-white ion-padding ion-margin-bottom rounded">
            <div className="d-flex ion-justify-content-between ion-align-items-center">
              <div className="d-flex ion-align-items-center">
                <IonThumbnail slot="start">
                  <img src="https://api.itaphoa.com/photos/fb3d3520aa8cde29f10e7cc53c902e5b.png"/>
                </IonThumbnail>
                <div className="ml-1">
                  <p className="ion-no-margin"><IonLabel color="dark" className="fw-bold fs-4">Dưa lưới TL3 (Trái từ 0.8 - dưới 1 kg)</IonLabel></p>
                  <IonLabel color="dark"><small>Trái = 28,500 ₫</small></IonLabel>
                </div>
              </div>
              <small className="text-muted">Đã hủy</small>
            </div>
            <div className="d-flex ion-justify-content-between border-top ion-margin-top ion-padding-top ion-align-items-center">
              <div className="d-flex ion-align-items-center">
                <IonThumbnail slot="start">
                  <img src="https://api.itaphoa.com/photos/0762d2752b82a22fe104cf68efe5dc0d.png"/>
                </IonThumbnail>
                <div className="ml-1">
                  <p className="ion-no-margin"><IonLabel color="dark" className="fw-bold fs-4">Sả Cây</IonLabel></p>
                  <IonLabel color="dark"><small>100 gram = 7,500 ₫</small></IonLabel>
                </div>
              </div>
              <small className="text-muted">Đã hủy</small>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default OrderDetail;
