import React, { useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonSearchbar,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonGrid,
  IonRow,
  IonList,
  IonCol,
  IonNote,
  IonChip, IonLabel
} from '@ionic/react';

// Components
import ProductItem from '../../components/product/item'

// Context
import { UIContext } from "../../context/ui";

// Services
import { product } from '../../services/mock'

const Search: React.FC = () => {
  const { setShowTabs } = React.useContext(UIContext);
  useEffect(() => {
    setShowTabs(false);
    return () => {
      setShowTabs(true);
    };
  }, []);
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" mode="md"/>
          </IonButtons>
            <IonSearchbar value={''} onIonChange={e => {}} searchIcon="search-outline" clearIcon="close-sharp" color="light"  placeholder="Tìm kiếm sản phẩm" className="mb-0 pb-0" style={{ '--border-radius': '20px', '--background': '#fff' }}/>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <IonGrid>
            <IonRow><IonCol><IonNote className="fw-bold" color="dark">Sản phẩm</IonNote></IonCol></IonRow>
            <IonRow>
              {product.map(item => (
                <ProductItem record={item} key={item.id.toString()}/>
              ))}
            </IonRow>
            <IonRow className="ion-margin-top"><IonCol><IonNote className="fw-bold" color="dark">Phổ Biến</IonNote></IonCol></IonRow>
            <IonChip outline={true} color="primary" style={{ 'background': '#fff' }}>
              <IonLabel color="dark">Bưởi</IonLabel>
            </IonChip>
            <IonChip outline={true} color="primary" style={{ 'background': '#fff' }}>
              <IonLabel color="dark">Gà</IonLabel>
            </IonChip>
            <IonChip outline={true} color="primary" style={{ 'background': '#fff' }}>
              <IonLabel color="dark">Rau</IonLabel>
            </IonChip>
            <IonChip outline={true} color="primary" style={{ 'background': '#fff' }}>
              <IonLabel color="dark">Thủy canh</IonLabel>
            </IonChip>
            <IonChip outline={true} color="primary" style={{ 'background': '#fff' }}>
              <IonLabel color="dark">Bò</IonLabel>
            </IonChip>
          </IonGrid>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Search;
