import React from "react";
import { Link } from 'react-router-dom';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonNote,
  IonIcon
} from '@ionic/react';
import { chevronForward, helpCircle, helpCircleOutline } from 'ionicons/icons';

const style = {
  list_item: { '--padding-start': 0, '--background': 'none', '--background-hover': 'none', '--color-hover': 'var(--ion-color-primary)' }
}

const Explore: React.FC = () => {
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonTitle>Quản lý</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonItem lines="none" style={{ '--background': '#fff' }}>
          <IonLabel>Thời gian</IonLabel>
          <IonSelect value={'1'} placeholder="Chọn thời gian" onIonChange={e => {}} okText="Chọn" cancelText="Hủy" mode="ios">
            <IonSelectOption value="1">Tất cả</IonSelectOption>
            <IonSelectOption value="2">Tháng 06/2021</IonSelectOption>
          </IonSelect>
        </IonItem>
        <div className="p-2">
          <p className="mb-0 fs-4">Thông tin khách hàng</p>
        </div>
        <IonGrid>
          <IonRow>
            <IonCol size="6">
              <Link to="/">
                <div className="bg-white p-2 rounded">
                  <div className="d-flex ion-justify-content-between mb-1">
                    <div><IonNote color="warning">1</IonNote><IonNote> / 12</IonNote></div>
                    <IonIcon icon={chevronForward} color="warning"/>
                  </div>
                  <IonNote className="fs-5 text-muted">Khách hàng có đơn</IonNote>
                </div>
              </Link>
            </IonCol>
            <IonCol size="6">
              <Link to="/">
                <div className="bg-white p-2 rounded">
                  <div className="d-flex ion-justify-content-between mb-1">
                    <IonNote color="warning">632,000₫</IonNote>
                    <IonIcon icon={chevronForward} color="warning"/>
                  </div>
                  <IonNote className="fs-5 text-muted">Tổng nhận được</IonNote>
                </div>
              </Link>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="p-2">
          <IonButton expand="block" color="warning" className="ion-text-nowrap h-100" mode="ios">
            <span className="px-2 fw-normal fs-4">
              Giới thiệu Khách hàng<br />
              https:://mio.cnnet.vn/0932970983
            </span>
          </IonButton>
          <div className="text-center mt-1"><Link to="/"><IonIcon icon={helpCircleOutline} color="warning" className="icon-center"/> <IonNote color="warning">Hướng dẫn giới thiệu Khách hàng</IonNote></Link></div>
        </div>
        <div className="p-2">
          <p className="mb-0 fs-4">Thông tin cộng tác viên</p>
        </div>
        <IonGrid>
          <IonRow>
            <IonCol size="6">
              <Link to="/">
                <div className="bg-white p-2 rounded">
                  <div className="d-flex ion-justify-content-between mb-1">
                    <div><IonNote color="tertiary">1</IonNote><IonNote> / 12</IonNote></div>
                    <IonIcon icon={chevronForward} color="tertiary"/>
                  </div>
                  <IonNote className="fs-5 text-muted">Cộng tác viên có đơn</IonNote>
                </div>
              </Link>
            </IonCol>
            <IonCol size="6">
              <Link to="/">
                <div className="bg-white p-2 rounded">
                  <div className="d-flex ion-justify-content-between mb-1">
                    <IonNote color="tertiary">0 ₫</IonNote>
                    <IonIcon icon={chevronForward} color="tertiary"/>
                  </div>
                  <IonNote className="fs-5 text-muted">Tổng nhận được</IonNote>
                </div>
              </Link>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="p-2">
          <IonButton expand="block" color="tertiary" className="ion-text-nowrap" mode="ios">
            <span className="px-2 fw-normal">
              Giới thiệu Cộng tác viên
            </span>
          </IonButton>
          <div className="text-center mt-1"><Link to="/"><IonIcon icon={helpCircleOutline} color="tertiary" className="icon-center"/> <IonNote color="tertiary">Hướng dẫn giới thiệu Cộng tác viên</IonNote></Link></div>
        </div>
        <div className="p-2">
          <p className="text-muted fs-4 mt-0">Chính sách</p>
          <div className="p-2 bg-white rounded">
            <IonItem onClick={() => { }} mode="ios" detail button lines="full" style={style.list_item}>
              <IonLabel>Chính sách Khách hàng</IonLabel>
            </IonItem>
            <IonItem onClick={() => { }} mode="ios" detail button lines="full" style={style.list_item}>
              <IonLabel>Chính sách Cộng tác viên</IonLabel>
            </IonItem>
            <IonItem onClick={() => { }} mode="ios" detail button lines="none" style={style.list_item}>
              <IonLabel>Chính sách đổi trả</IonLabel>
            </IonItem>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Explore;
