import React, { useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import {
  IonContent,
  IonPage,
  IonSlides,
  IonSlide,
  IonGrid,
  IonRow,
  IonList,
  IonNote,
  useIonToast,
  IonIcon
} from '@ionic/react'
import { RefresherEventDetail } from '@ionic/core';
import { cartOutline } from "ionicons/icons";

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Avatar from '@material-ui/core/Avatar'

// Libs
import $http from '../../libs/axios'

// Components
import ProductItem from '../../components/product/item'
import CartProgress from "../../components/cart/progress"

// Context
import { UIContext } from "../../context/ui"
import { StoreContext } from "../../context/store"

// Models
import { Category } from '../../models/category'

// Config
const slideOpts = {
  slidesPerView: 1.2,
  centeredSlides: true,
  spaceBetween: 15,
  loop: true,
  speed: 400,
  autoplay: true
}

function a11yProps(index: any) {
  return {
    'id': `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
// End Tab

const Home: React.FC = () => {
  let location = useLocation()
  const [tab, setTab] = React.useState(0)
  const [recordsCategory, setRecordsCategory] = React.useState(Array<Category>())
  const { setShowTabs } = React.useContext(UIContext)
  const { isLogin, cart } = React.useContext(StoreContext)
  useEffect(() => {
    setShowTabs(isLogin)
    const getRecords = async () => {
      await getRecordsCategory()
      await getRecordsProduct(0)
    }
    getRecords()
    return () => {
      setShowTabs(isLogin)
    };
  }, [location]);

  const handleChange = (event: React.ChangeEvent<{}>, index: number) => {
    setTab(index)
    getRecordsProduct(index)
  };
  const handleChangeIndex = (index: number) => {
    setTab(index)
    getRecordsProduct(index)
  };
  const [presentToast, dismissToast] = useIonToast();
  return (
    <IonPage>
      <IonContent fullscreen slot="fixed">
        <div className="search-home">
          <Link to="/search">
            <div className="search-home-input d-flex ion-align-items-center">
              <img src="/assets/images/search/icon.png" width="20px" />
              <IonNote className="fs-3 text-muted ml-1">Tìm kiếm sản phẩm</IonNote>
            </div>
          </Link>
        </div>
        <div className="bg-white"><CartProgress total={0} max={200000}/></div>
        <IonSlides pager={true} options={slideOpts}>
          <IonSlide>
            <img src='https://api.itaphoa.com/photos/33684fab28c03316644754122be66dd5.png' className="rounded"/>
          </IonSlide>
          <IonSlide>
            <img src='https://api.itaphoa.com/photos/b8587d8716be17b06d7d615288a3953d.png' className="rounded"/>
          </IonSlide>
          <IonSlide>
            <img src='https://api.itaphoa.com/photos/71a0f15ae169110df1376575c85517e1.png' className="rounded"/>
          </IonSlide>
        </IonSlides>
        <AppBar position="sticky" color="secondary" classes={{ root: 'bg-white ion-margin-top' }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            classes={{ scrollButtons: 'w-auto' }}
          >
            {recordsCategory.map((category, index) => (
              <Tab label={category.name} key={(category.id).toString()} classes={{ root: 'ion-text-capitalize py-1 w-auto' }}  icon={<Avatar alt="" src={category.avatar.url} />} {...a11yProps(index)} />
            ))}
          </Tabs>
        </AppBar>
        <SwipeableViews
          index={tab}
          onChangeIndex={handleChangeIndex}
          className="hm-full"
        >
          {recordsCategory.map((category, index) => (
            <div key={(category.id).toString()}>
              <IonList className="mb-1">
                <IonGrid>
                  <IonRow>
                    {category.product.map(item => (
                      <ProductItem record={item} key={item.id.toString()}/>
                    ))}
                  </IonRow>
                </IonGrid>
              </IonList>
            </div>
          ))}
        </SwipeableViews>
        {blockAuth(isLogin)}
      </IonContent>
      {blockCart(isLogin)}
    </IonPage>
  );
  
  async function getRecordsCategory() {
    if(!recordsCategory || recordsCategory.length == 0) {
      await $http.get('/category').then(response => {
        setRecordsCategory(response.data)
      }).catch(error => {
        presentToast({message: error.response ? error.response.data.message : 'Đã có lỗi kết nối', buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'warning', mode: 'ios', position: 'top'});
      })
    }
  }

  async function getRecordsProduct(index: number) {
    let recordsCategoryState: Array<Category> = recordsCategory
    setRecordsCategory((state) => {
      recordsCategoryState = state;
      return state;
    })
    const recordCategory = recordsCategoryState[index]
    if(recordCategory && recordCategory.product.length == 0) {
      await $http.get(`/category/${recordCategory.id}/product`).then(response => {
        let recordsCategoryOrigin = [...recordsCategoryState];
        recordsCategoryOrigin[index].product = response.data
        setRecordsCategory(recordsCategoryOrigin)
      }).catch(error => {
        presentToast({message: error.response ? error.response.data.message : 'Đã có lỗi kết nối', buttons: [{ text: 'Ẩn', handler: () => dismissToast() }], duration: 3000, color: 'warning', mode: 'ios', position: 'top'});
      })
    }
  }

  function blockAuth (isLogin: boolean) {
    return (
      !isLogin ? (
        <div className="auth text-center p-2" slot="fixed">
          <p className="ion-no-margin fs-4"><IonNote>Bạn được giới thiệu bởi</IonNote></p>
          <p className="ion-no-margin fs-4"><IonNote color="dark" className="fw-bold">Cao Sơn</IonNote><IonNote> - Địa chỉ: 154 Âu Dương Lân, P3, Q8, TP HCM</IonNote></p>
          <hr />
          <p className="ion-no-margin ion-margin-bottom">
            <Link to="/auth" className="fw-bold">Đăng nhập ngay</Link>
          </p>
        </div>
      ) : null
    );
  }

  function blockCart(isLogin: boolean) {
    return (
      cart && (cart[0].length || cart[1].length) ? (
        <div className={"cart-min" + (isLogin ? ' is-login' : '')}>
          <Link to="/cart">
            <div className="cart-min-body">
              <div className="cart-min-icon">
                <IonIcon icon={cartOutline} size="large"/>
              </div>
              <div className="cart-min-qty">
                {cart[0].length}
              </div>
            </div>
          </Link>
        </div>
      ) : null
    )
  }
};
export default Home;
