import React from "react";
import {
  IonProgressBar,
  IonNote,
  IonIcon
} from '@ionic/react';
import { chevronForward } from "ionicons/icons";

// Services
import { formatNumber } from '../../services/utils'

interface props {
  total : number,
  max: number
}
const CartProgress: React.FC<props> = ({ total, max }) => {
  const progress = (total > max ? max : total) / max;
  return (
    <div className="cart-progress ion-padding ion-margin-bottom d-flex ion-justify-content-between rounded ion-align-items-center">
        <img src="/assets/images/cart/medal.png" width="30px"/>
        <div className="cart-progress-bar position-relative">
            {total > max ? (
              <p className="fs-5 mt-0 mb-1">Nhóm gom đơn đã đạt được <IonNote color="primary" className="fw-bold">Tối thiểu</IonNote></p>
            ) : (
              <p className="fs-5 mt-0 mb-1">Bạn cần mua thêm <IonNote color="success" className="fw-bold">{formatNumber(Math.round(max - total))} ₫</IonNote> để đạt mốc Tối thiểu</p>
            )}
            <IonProgressBar value={progress} color="tertiary"></IonProgressBar>
            <IonNote className="milestones milestones-from">Mua chung</IonNote>
            <IonNote className="milestones milestones-to">Tối thiểu</IonNote>
        </div>
        <IonIcon icon={chevronForward} className="text-muted" />
    </div>
  );
};

export default CartProgress;
